<template>
  <div class="page" v-if="$store.project">
     <vue-headful
            :title="$store.metadata.title + ' | Edgeryders'"
            :description="$store.header.text"
        />
    <Header :meta="$store.metadata" />
    <main>

    <Content v-if="$store.views" :views="$store.views"  />
    </main>
  </div>
</template>

<script>
// @ is an alias to /src
import Content from '@/components/Content.vue'
import Header from '@/components/Header.vue'

export default {
  name: 'Home',
  components: {
    Content,
    Header
  },
  created(){
    window.scrollTo(0,0);
  },
  mounted(){
    this.$store.type = 'page';
    this.$store.blob.active = true;
    this.loadPage(this.$route.params.id);
    this.fetchJSON(this.$route.params.id);
  }
}
</script>

<style lang="scss">
.page {
main {
  width: 70%;
  max-width: 1010px;
  @apply mx-auto;
}
}
@media only screen and (max-width: 600px) { 
.page {
main {
  width: 90%;
max-width: 90%;}
}

}
</style>

